/* @flow */
import React, { Component, type Node } from "react";
import classNames from "classnames";
import CheckboxInput from "forms/CheckboxInput";
import hasMultipleTwitterAccounts from "./hasMultipleTwitterAccounts";
import hasInstagramAccounts from "./hasInstagramAccounts";
import hasPinterestAccounts from "./hasPinterestAccounts";
import EmptyState from "./EmptyState";
import OneTwitterAccountPerContentAlert from "./OneTwitterAccountPerContentAlert";
import ImageRequiredForRssAlert from "./ImageRequiredForRssAlert";
import Option from "./Option";
import styles from "./index.css";

import type { Account, AccountProvider } from "types";

// TODO: displayNoInstagramAlert, displayNoPinterestAlert props are specific to RSS feeds.
// Similarly so is <ImageRequiredForRssAlert />. This is the common <AccountSelect /> component
// so that alert should be getting injected from the RSS code via the alerts prop

type Props = {
  accounts: Account[],
  selected: string[],
  selectedPinterestBoardsByAccount: ?{ [key: string]: ?string },
  allowMultipleTwitter: boolean,
  hasError: boolean,
  stackOptions?: boolean,
  disabledMessaging?: { [string]: string },
  disabledProviders?: AccountProvider[],
  hiddenProviders?: AccountProvider[],
  disabledAccountIds?: string[],
  alerts?: Node[],
  displayOneTwitterAccountPerAlert: boolean,
  displayNoInstagramAlert?: boolean,
  displayNoPinterestAlert?: boolean,
  className?: string,
  sortByEnabled?: boolean,
  sendMobileReminder?: Boolean,
  instaReels?: Boolean,
  hasMobileDevices?: Boolean,
  onSelect?: (id: string[]) => void,
  onDeselect?: (id: string[]) => void,
  onChange?: (ids: string[]) => void,
  onSelectPinterestBoard?: (
    pinterestBoardId: string,
    accountId: string
  ) => void,
  onChangeInstagramBusinessSetting?: (
    sendMobileReminder: boolean,
    instaReels?: boolean
  ) => void
};

let numDisallowedAccounts: number = 0;
let _accounts: Account[] = [];

export default class AccountSelect extends Component<Props> {
  handleToggleAll = (): void => {
    const { selected, onChange, onSelect, onDeselect } = this.props;
    const enabledAccounts = _accounts.filter(
      acct => !this.disallowAccountSelection(acct)
    );
    if (selected.length === enabledAccounts.length) {
      onDeselect && onDeselect(selected);
      onChange && onChange([]);
    } else {
      const notTwitter = enabledAccounts.filter(
        a => a.platform.toUpperCase() !== "TWITTER"
      );
      const firstTwitter = enabledAccounts.find(
        a => a.platform.toUpperCase() === "TWITTER"
      );
      const newlySelectedNotTwitter = notTwitter.filter(
        a => !selected.includes(a.id)
      );
      if (firstTwitter) {
        onSelect &&
          onSelect(
            newlySelectedNotTwitter
              .concat(
                // Include firstTwitter if it wasn't already selected
                firstTwitter && selected.includes(firstTwitter.id)
                  ? []
                  : [firstTwitter]
              )
              .map(a => a.id)
          );
        onChange && onChange([...notTwitter, firstTwitter].map(a => a.id));
      } else {
        onSelect && onSelect(newlySelectedNotTwitter.map(a => a.id));
        onChange && onChange([...notTwitter].map(a => a.id));
      }
    }
  };

  handleSelect: (id: string) => void = id => {
    const { selected, onChange, onSelect, onDeselect } = this.props;
    if (selected.some(s => s === id)) {
      onDeselect && onDeselect([id]);
      onChange && onChange(selected.filter(s => s !== id));
    } else {
      onSelect && onSelect([id]);
      onChange && onChange([...selected, id]);
    }
  };

  disallowAccountSelection = (account: Account): boolean => {
    const { disabledAccountIds = [] } = this.props;
    if (disabledAccountIds.includes(account.id)) {
      return true;
    }
    const { disabledProviders = [] } = this.props;
    if (disabledProviders.indexOf(account.provider) >= 0) {
      return true;
    }
    const { accounts, selected } = this.props;
    if (
      account.platform.toUpperCase() !== "TWITTER" ||
      selected.includes(account.id)
    ) {
      return false;
    }
    const { allowMultipleTwitter = false } = this.props;
    if (allowMultipleTwitter) {
      return false;
    }
    return accounts.some(
      a => a.platform.toUpperCase() === "TWITTER" && selected.includes(a.id)
    );
  };

  hasInstagramAccounts = () => hasInstagramAccounts(this.props.accounts);

  hasPinterestAccounts = () => hasPinterestAccounts(this.props.accounts);

  calcLengthOfAvailableAccounts = () => {
    return _accounts.length - numDisallowedAccounts;
  };

  formatName = (account: Account) => {
    const { name, nickname, platform } = account;
    return platform && platform.match(/(INSTAGRAM|PINTEREST)/)
      ? `@${nickname ?? ""}`
      : name;
  };

  prepareAccountsForDisplay = (accounts: Account[]) => {
    _accounts = [];
    // remove any accounts that are not allowed to be shown
    const { hiddenProviders = [] } = this.props;
    if (hiddenProviders.length) {
      accounts.forEach(a => {
        if (!hiddenProviders.includes(a.provider)) {
          _accounts.push(a);
        }
      });
    } else {
      _accounts = accounts;
    }

    numDisallowedAccounts = 0;
    if (this.props.sortByEnabled) {
      _accounts = _accounts.map(x => {
        const newObj = Object.assign({}, x, { isDisallowed: null });
        return newObj;
      })
      _accounts.forEach(a => {
        if (this.disallowAccountSelection(a)) {
          a.isDisallowed = true;
          numDisallowedAccounts += 1;
        } else {
          a.isDisallowed = false;
        }
      });

      let _isDisallowed = [];
      let _isAllowed = [];
      _accounts.sort((a, b) => {
        if (
          typeof a.isDisallowed === "boolean" &&
          typeof b.isDisallowed === "boolean"
        ) {
          if (a.isDisallowed && !b.isDisallowed) {
            return 1;
          } else if (!a.isDisallowed && b.isDisallowed) {
            return -1;
          }

          // ... if they are both allowed or disallowed ...
          // sort by platform ...
          if (a.platform < b.platform) {
            return -1;
          } else if (a.platform > b.platform) {
            return 1;
          }

          // then sort by name ...
          if (this.formatName(a) < this.formatName(b)) {
            return -1;
          } else if (this.formatName(a) > this.formatName(b)) {
            return 1;
          }
          return 0;
        }
        return 0;
      });
    }
  };

  render() {
    const {
      className,
      accounts,
      selected,
      selectedPinterestBoardsByAccount,
      stackOptions,
      alerts = [],
      hasError,
      displayOneTwitterAccountPerAlert,
      displayNoInstagramAlert,
      displayNoPinterestAlert,
      disabledMessaging,
      sendMobileReminder,
      instaReels,
      hasMobileDevices
    } = this.props;

    // add an option to sort accounts array such that disabled accounts are listed last
    // we have to perform an initial default sort to maintain visual sanity for the user
    this.prepareAccountsForDisplay(accounts);

    if (!_accounts.length) {
      return <EmptyState />;
    }
    const showOneTwitterAccountPerAlert =
      displayOneTwitterAccountPerAlert && hasMultipleTwitterAccounts(_accounts);
    const hasImageRssWarning =
      (displayNoInstagramAlert && this.hasInstagramAccounts()) ||
      (displayNoPinterestAlert && this.hasPinterestAccounts());

    const hasAlerts =
      !!alerts.length || showOneTwitterAccountPerAlert || hasImageRssWarning;

    return (
      <div className={className || ""}>
        <CheckboxInput
          value={selected.length === this.calcLengthOfAvailableAccounts()}
          label={`Accounts (${
            selected.length
          }/${this.calcLengthOfAvailableAccounts()})`}
          className={classNames(styles.toggleAll, {
            [styles.toggleAllStacked]: stackOptions
          })}
          extraMargin
          onChange={this.handleToggleAll}
        />

        <div
          className={classNames(styles.list, {
            [styles.accountsStacked]: stackOptions
          })}
        >
          {_accounts.map(a => (
            <Option
              key={a.id}
              account={a}
              disabledMessaging={disabledMessaging}
              fullWidth={stackOptions}
              selected={selected.some(id => id === a.id)}
              selectedPinterestBoardId={
                selectedPinterestBoardsByAccount?.[a.id]?.[0]
              }
              disabled={this.disallowAccountSelection(a)}
              hasError={hasError}
              formattedName={this.formatName(a)}
              sendMobileReminder={sendMobileReminder}
              instaReels={instaReels}
              hasMobileDevices={hasMobileDevices}
              onSelect={this.handleSelect}
              onSelectPinterestBoard={this.props.onSelectPinterestBoard}
              onChangeInstagramBusinessSetting={
                this.props.onChangeInstagramBusinessSetting
              }
            />
          ))}
        </div>
        {hasAlerts && (
          <div className={styles.alerts}>
            {alerts.map((alert, i) => (
              <div key={i}>{alert}</div>
            ))}

            {showOneTwitterAccountPerAlert && (
              <OneTwitterAccountPerContentAlert />
            )}

            {hasImageRssWarning && (
              <ImageRequiredForRssAlert
                hasPinterest={this.hasPinterestAccounts()}
                hasInstagram={this.hasInstagramAccounts()}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
