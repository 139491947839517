/* @flow */
import React, { useState } from "react";
import classNames from "classnames";
import { featureFlag } from "util";
import { useApolloClient } from "@apollo/react-hooks";

import { useQuery } from "@apollo/react-hooks";
import GET_USER_DETAILS from "queries/getUserDetails";
import { Icon } from "icons";
import { Label, Caption } from "typography";
import Modal from "layout/modals/Modal";
import LoadingIcon from "icons/LoadingIcon";

import googleLogo from "../../common/images/google-my-business-white.svg";
import xLogo from "../../common/images/x-logo.png";
import threadsLogo from "../../common/images/threads-logo.png";
import styles from "./index.css";

const INSTAGRAM_MODAL_HELP_LINK =
  "https://meetedgar.com/blog/instagram-business-account-whats-the-difference/";

type Props = {
  generateOAuthUri: (provider: string, uid: ?string) => string
};

export function ProviderList({ generateOAuthUri }: Props) {
  const [loadingFromClick, setLoading] = useState(false);
  const [showInstagramModal, setShowInstagramModal] = useState(false);
  const { data, loading } = useQuery(GET_USER_DETAILS);
  const userData = data?.user?.company;
  const accountLimit =
    userData?.subscriptionPlan?.accountLimit +
    userData?.additionalAccounts -
    userData?.totalAccounts;

  function handleCloseInstagramModal() {
    setShowInstagramModal(false);
  }

  if (loading) {
    return <LoadingIcon className={styles.loading} />;
  }

  return (
    <div className={styles.container}>
      <Provider
        setShowInstagramModal={setShowInstagramModal}
        generateOAuthUri={generateOAuthUri}
        accountLimit={accountLimit}
        provider="facebook"
        displayName="Page"
        loading={loadingFromClick}
        setLoading={setLoading}
      />
      <Provider
        setShowInstagramModal={setShowInstagramModal}
        generateOAuthUri={generateOAuthUri}
        accountLimit={accountLimit}
        provider="instagram"
        displayName="instagram"
        loading={loadingFromClick}
        setLoading={setLoading}
      />
      <Provider
        setShowInstagramModal={setShowInstagramModal}
        generateOAuthUri={generateOAuthUri}
        accountLimit={accountLimit}
        provider="linkedin"
        displayName="Profile"
        loading={loadingFromClick}
        setLoading={setLoading}
      />
      <Provider
        setShowInstagramModal={setShowInstagramModal}
        generateOAuthUri={generateOAuthUri}
        accountLimit={accountLimit}
        provider="linkedin standalone company"
        displayName="Company"
        loading={loadingFromClick}
        setLoading={setLoading}
      />
      {featureFlag("pinterest") && (
        <Provider
          setShowInstagramModal={setShowInstagramModal}
          generateOAuthUri={generateOAuthUri}
          accountLimit={accountLimit}
          provider="pinterest"
          displayName="pinterest"
          loading={loadingFromClick}
          setLoading={setLoading}
        />
      )}
      <Provider
        setShowInstagramModal={setShowInstagramModal}
        generateOAuthUri={generateOAuthUri}
        accountLimit={accountLimit}
        provider="twitter"
        displayName="twitter"
        loading={loadingFromClick}
        setLoading={setLoading}
      />
      <Provider
        setShowInstagramModal={setShowInstagramModal}
        generateOAuthUri={generateOAuthUri}
        provider="google"
        displayName="google business"
        accountLimit={accountLimit}
        loading={loadingFromClick}
        setLoading={setLoading}
      />
      <Provider
        setShowInstagramModal={setShowInstagramModal}
        generateOAuthUri={generateOAuthUri}
        provider="tiktok"
        displayName="tiktok"
        accountLimit={accountLimit}
        loading={loadingFromClick}
        setLoading={setLoading}
      />
      <Provider
        setShowInstagramModal={setShowInstagramModal}
        generateOAuthUri={generateOAuthUri}
        provider="threads"
        displayName="threads"
        accountLimit={accountLimit}
        loading={loadingFromClick}
        setLoading={setLoading}
      />
      {showInstagramModal && (
        <Modal
          closeOnOverlayClick
          size="medium"
          autoFocus={false}
          onClose={handleCloseInstagramModal}
        >
          <div className={styles.instagramModalSection}>
            <Label className={styles.instagramModalLabel}>
              Connect an Instagram Professional Profile through Facebook Login
            </Label>
            <Caption className={styles.instagramModalCaption}>
              Facebook allows images and video posts to be published to
              Instagram automatically. Your Instagram business account must be
              connected to a Facebook page. Click below to connect your
              Instagram through Facebook Login.
            </Caption>
            <div className={styles.instagramModalButton}>
              <Provider
                setShowInstagramModal={setShowInstagramModal}
                generateOAuthUri={generateOAuthUri}
                accountLimit={accountLimit}
                provider="instagram business"
                displayName="Business"
                loading={loadingFromClick}
                setLoading={setLoading}
              />
            </div>
          </div>

          <div className={styles.instagramModalSection}>
            <Label className={styles.instagramModalLabel}>
              Connect an Instagram Professional Profile through Instagram Login
            </Label>
            <Caption className={styles.instagramModalCaption}>
              Instagram allows images and video posts to be published to
              Instagram automatically. Click below to connect your
              Instagram through Instagram Login.
            </Caption>
            <div className={styles.instagramModalButton}>
              <Provider
                setShowInstagramModal={setShowInstagramModal}
                generateOAuthUri={generateOAuthUri}
                accountLimit={accountLimit}
                provider="instagram professional"
                displayName="Business"
                loading={loadingFromClick}
                setLoading={setLoading}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

const Provider = ({
  provider,
  displayName,
  generateOAuthUri,
  setShowInstagramModal,
  accountLimit,
  loading,
  setLoading
}) => {
  const platform = provider.split(" ")[0] == "twitter" ? "x" : provider.split(" ")[0];
  const apolloClient = useApolloClient();

  return (
    <a
      id={`provider-oauth-${provider.replace(" ", "_")}`}
      className={classNames(
        styles.socialContainer,
        styles[platform],
        loading ? styles.loadingButton : {}
      )}
      onClick={async function () {
        if (loading) return;
        if (accountLimit <= 0) {
          try {
            setLoading(true);
            const res = await apolloClient.query({
              query: GET_USER_DETAILS,
              fetchPolicy: "network-only"
            });

            const userData = res?.data?.user?.company;

            const newAccountLimit =
              userData?.subscriptionPlan?.accountLimit +
              userData?.additionalAccounts -
              userData?.totalAccounts;

            if (newAccountLimit <= 0) {
              window.location.href = "accounts?limit_reached=true";
              return;
            }
          } catch (err) {
            setLoading(false);
          }
        }

        if (provider !== "instagram") {
          window.location.href = await generateOAuthUri(provider);
        } else {
          setShowInstagramModal(true);
          setLoading(false);
        }
      }}
    >
      {platform === "google" ? (
        <img src={googleLogo} alt="google" className={styles.socialImg} />
      ) : (platform === "x" ? (
        <img src={xLogo} alt="x_logo" className={styles.socialImg} />
      ) : (platform === "threads" ? (
        <img src={threadsLogo} alt="threads_logo" className={styles.socialImg} />
      ) :
        (
          <Icon
            type={`${platform}-square`}
            size="2x"
            className={styles.socialIcon}
          />
        )
      ))}
      <div className={styles.providerText}>
        {platform !== "google" && "Add "}
        {platform === "x" ? "X" : displayName}
      </div>
    </a>
  );
};

export default ProviderList;
